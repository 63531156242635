<template>
    <div>
        <div id="logo-box">
            <img src="./../../img/mode-logo-dark.png" id="auth-logo" />
        </div>

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    #logo-box {
        margin-top: 100px;
        margin-bottom: 100px;
        text-align: center;
    }
    #auth-logo {
        width: 400px;
        max-width: 80%

    }
</style>