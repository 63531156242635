<template>
    <div>
       <form @submit.prevent="signIn()">
           <div class="text-danger mb-3" v-if="feedback.invalid">{{feedback.invalid}}</div>
           <div class="form-group">
               <input type="email" class="form-control" placeholder="Email" v-model="auth.email" />
            </div>
            <div class="form-group">
                <input type="password" class="form-control" placeholder="Password" v-model="auth.password" />
            </div>

            <button class="btn btn-primary" type="submit" :disabled="submitting">Sign In</button>
        </form>

        <div class="mt-3"><a href="/auth/password-reset">Forgot your password?</a></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            auth: {
                email: '',
                password: '',
            },
            feedback: {
                invalid: null,
            },
            submitting: false,
        }
    },
    methods: {
        signIn() {
            this.feedback.invalid = null
            this.submitting = true;
            this.axios.post("/v/auth/signin", this.auth).then(response => {
                this.submitting = false;
                if(response.status == 200 && response.data.ok) {
                    window.location.href = '/admin';
                } else {
                    this.feedback.invalid = "Something went wrong there.  Check your credentials and try again.";
                }
            }).catch(e => {
                this.submitting = false;
                if(e.response.status == 401) {
                    this.feedback.invalid = "That didn't look right.  Check your credentials and try again.";
                } else if (e.response.status == 307) {
                    console.log("307 response so reloading...")
                    window.location.reload();
                } else {
                    this.feedback.invalid = "Something went wrong with your request.  Check your credentials and try again.";
                }
            })

        }
    }
}
</script>