import axios from "axios";

const instance = axios.create({ withCredentials: true });

let csrfToken = null;

instance.interceptors.request.use(function(config) {
  if(csrfToken) {
    config.headers['X-CSRF-Token'] = csrfToken;
  }
  return config;
}, function(error) {
  console.error(error);
  return Promise.reject(error);
});

instance.interceptors.response.use(function(response) {
  let t = response.headers['x-csrf-token'];
  if(t) {
    csrfToken = t;
  }
  return response;
},function(error) {
  return Promise.reject(error);
});

export default instance;
