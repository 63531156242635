import 'jquery';
import 'bootstrap';
import './shared.scss';

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import axios from "./axios.js";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

Vue.use(require('vue-moment'));

/*
import csrfInit from "./csrfinit.js";
Vue.use(csrfInit);
*/


import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon.vue';
Vue.component('v-icon', Icon);

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
});

export default {
  Vue,
  VueRouter,
  axios,
}
