var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.codeValid
      ? _c("div", [
          _c("div", [_vm._v("Create a new password:")]),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.errorMessage))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.resetPassword($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPassword.password,
                      expression: "newPassword.password"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password", placeholder: "New Password" },
                  domProps: { value: _vm.newPassword.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.newPassword, "password", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPassword.confirm,
                      expression: "newPassword.confirm"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password", placeholder: "Confirm" },
                  domProps: { value: _vm.newPassword.confirm },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.newPassword, "confirm", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: _vm.sending },
                domProps: { value: _vm.sending ? "Saving..." : "Save" }
              })
            ]
          )
        ])
      : _c("div", [
          _vm.sent
            ? _c("div", [
                _c("div", { staticClass: "mb-4" }, [
                  _vm._v(
                    "Please check your email for instructions on how to complete the reset process."
                  )
                ]),
                _vm._v(" "),
                _vm._m(0)
              ])
            : _c("div", [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.sendRequest($event)
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("Enter your email address to proceed:")]),
                    _vm._v(" "),
                    _vm.errorMessage
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errorMessage))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newEmail,
                            expression: "newEmail"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          placeholder: "your email address...",
                          required: ""
                        },
                        domProps: { value: _vm.newEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newEmail = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit", disabled: _vm.sending },
                      domProps: { value: _vm.sending ? "Sending..." : "Send" }
                    })
                  ]
                )
              ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("Or "),
      _c("a", { attrs: { href: "/auth/login" } }, [_vm._v("Sign In")]),
      _vm._v(" instead.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }