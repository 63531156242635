var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.signIn()
          }
        }
      },
      [
        _vm.feedback.invalid
          ? _c("div", { staticClass: "text-danger mb-3" }, [
              _vm._v(_vm._s(_vm.feedback.invalid))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.auth.email,
                expression: "auth.email"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "email", placeholder: "Email" },
            domProps: { value: _vm.auth.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.auth, "email", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.auth.password,
                expression: "auth.password"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "password", placeholder: "Password" },
            domProps: { value: _vm.auth.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.auth, "password", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit", disabled: _vm.submitting }
          },
          [_vm._v("Sign In")]
        )
      ]
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3" }, [
      _c("a", { attrs: { href: "/auth/password-reset" } }, [
        _vm._v("Forgot your password?")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }