var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-6" }, [_c("router-view")], 1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "logo-box" } }, [
      _c("img", {
        attrs: {
          src: require("./../../img/mode-logo-dark.png"),
          id: "auth-logo"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }