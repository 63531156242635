<template>
    <div>
        <div>You're Invited</div>
        <p>Continue by creating your account.</p>
        <p class="text-danger" v-if="errorMessage != ''">{{errorMessage}}</p>
        <form @submit.prevent="register()">
            <div class="form-group">
                <input type="email" class="form-control" v-model="user.email" placeholder="Email..." />
            </div>
            <div class="form-group">
                <input type="password" class="form-control" v-model="user.password" placeholder="Password..." />
            </div>
            <input type="submit" value="Next" class="btn btn-primary" />
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: { email: "", password: "" },
            invitationId: this.$route.params.invitationId,
            errorMessage: "",
        }
    },
    mounted() {
        console.log(this.invitationId)
    },
    methods: {
        register() {
            this.axios.post("/v/auth/registrations/"+this.invitationId, this.user).then(response => {
                window.location.href = "/";
            }).catch(e => {
                this.errorMessage = "Something went wrong there.  Please try again!"
            });
        }
    }
}
</script>