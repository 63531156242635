var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v("You're Invited")]),
    _vm._v(" "),
    _c("p", [_vm._v("Continue by creating your account.")]),
    _vm._v(" "),
    _vm.errorMessage != ""
      ? _c("p", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errorMessage))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.register()
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.email,
                expression: "user.email"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "email", placeholder: "Email..." },
            domProps: { value: _vm.user.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "email", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.password,
                expression: "user.password"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "password", placeholder: "Password..." },
            domProps: { value: _vm.user.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "password", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "btn btn-primary",
          attrs: { type: "submit", value: "Next" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }