<template>
    <div>
        <div v-if="codeValid">
            <div>Create a new password:</div>
            <div v-if="errorMessage" class="text-danger">{{errorMessage}}</div>
            <form @submit.prevent="resetPassword">
                <div class="form-group">
                    <input type="password" v-model="newPassword.password" class="form-control" placeholder="New Password" />
                </div>
                <div class="form-group">
                    <input type="password" v-model="newPassword.confirm" class="form-control" placeholder="Confirm" />
                </div>
                <input type="submit" :value="sending ? 'Saving...' : 'Save'" :disabled="sending" class="btn btn-primary" />
            </form>
        </div>

        <div v-else>
            <div v-if="sent">
                <div class="mb-4">Please check your email for instructions on how to complete the reset process.</div>
                <div>Or <a href="/auth/login">Sign In</a> instead.</div>
            </div>

            <div v-else>
                <form @submit.prevent="sendRequest">
                    <div>Enter your email address to proceed:</div>
                    <div v-if="errorMessage" class="text-danger">{{errorMessage}}</div>
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="your email address..." v-model="newEmail" required />
                    </div>
                    <input type="submit" :value="sending ? 'Sending...' : 'Send'" class="btn btn-primary" :disabled="sending" />
                </form>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            code: this.$route.query.c,
            codeValid: false,
            sending: false,
            sent: false,
            newEmail: "",
            errorMessage: "",
            newPassword: {},
        }
    },
    mounted() {
        this.checkCode()
    },
    methods: {
        checkCode() {
            if(!this.code || this.code.length==0) {
                return
            }
            this.axios.get("/v/auth/password-resets/"+this.code).then(response => {
                if(response.data.ok) {
                    this.codeValid = true;
                } else {
                    this.$router.push("/auth/login");
                }
            }).catch(e => {
                this.$router.push("/auth/login");
            })
        },
        sendRequest() {
            this.sending = true;
            this.errorMessage = null;
            let payload = { email: this.newEmail };
            this.axios.post("/v/auth/password-resets", payload).then(response => {
                this.sending = false;
                if(response.data.ok) {
                    this.sent = true;
                } else {
                    this.errorMessage = response.data.message;
                }
            }).catch(e => {
                this.sending = false;
                this.errorMessage = "Something went wrong.  Please try again."
            });
        },
        resetPassword() {

            this.errorMessage = null;

            if(this.newPassword.password != this.newPassword.confirm) {
                this.errorMessage = "Passwords do not match.";
                return
            }

            let payload = { password: this.newPassword.password }
            this.sending = true;
            this.axios.put("/v/auth/password-resets/"+this.code, payload).then(response => {
                this.sending = false;
                if(response.data.ok) {
                    window.location.href = "/";
                } else {
                    this.errorMessage = response.data.message;
                }
            }).catch(e => {
                this.sending = false;
                this.errorMessage = "Something went wrong.  Please try again.";
            });
        }
    }
}
</script>