import shared from "./shared.js";

import IndexVue from "./components/auth/index.vue";
import Login from "./components/auth/login.vue";
import Invitation from "./components/auth/invitation.vue";
import PasswordReset from "./components/auth/password-reset.vue";

const routes = [
    { path: "/auth/login", component: Login },
    { path: "/auth/invitations/:invitationId", component: Invitation },
    { path: "/auth/password-reset", component: PasswordReset },
    { path: "*", redirect: "/" },
];

const router = new shared.VueRouter({
    mode: "history",
    routes
});

var app = new shared.Vue({
    router,
    template: "<index-vue></index-vue>",
    components: {
        'index-vue': IndexVue,
    },
}).$mount("#app");

